<template>
  <div class="content-index" style="padding:20px;overflow:auto">
    <div
      class="flex-row"
      style="margin-bottom:20px;cursor:pointer;height:36px"
      @click="back"
    >
      <i class="el-icon-back" style="font-size:18px;color:#000"></i>
      <span style="padding-left:10px"
        ><span style="color:#8A9099">商品{{ text }} </span>/ 详情</span
      >
    </div>
    <div v-loading="loading" class="drawer-content">
      <div style="font-size: 16px; font-weight: 700">{{ text }}单信息</div>
      <div class="flex-row" style="padding-top: 20px">
        <span style="flex: 1; font-size: 14px">
          {{ text }}单号： {{ detailsData.recordNumber }}
        </span>
        <span style="flex: 1; font-size: 14px">
          {{ text }}类型： {{ detailsData.inventoryTypeDesc }}
        </span>
      </div>
      <div class="flex-row" style="padding-top: 10px">
        <span style="flex: 1; font-size: 14px">
          {{ text }}时间：{{ detailsData.createdTime }}
        </span>
        <span class="box" style="flex: 1; font-size: 14px">
          备注： {{ detailsData.remark }}
        </span>
      </div>
      <div style="font-size: 16px; font-weight: 700; padding: 20px 0 10px">
        商品明细
      </div>
      <div class="table-box">
        <Table
          :header-style="headerStyle"
          :height="''"
          :orientation="'center'"
          :page="true"
          :table-data="tableData"
          :title-list="titleListData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@/components/table2";
import { getInventoryRecordDetail } from "@/api/commodity";
export default {
  components: {
    Table,
  },
  data() {
    return {
      text: "",

      headerStyle: {
        background: "#f5f7fa",
        height: "50px",
      },
      tableData: [],
      titleListData: [
        {
          prop: "goodsName",
          label: "商品名称",
        },
        {
          prop: "barcode",
          label: "规格条码",
        },
        {
          prop: "specification",
          label: "商品规格",
        },
        {
          prop: "orderGoodsUnit",
          label: "单位",
        },
        {
          prop: "num",
          label: this.text + "数量",
        },
      ],
      loading: false,
      detailsData: {},
    };
  },
  created() {
    this.text = this.$route.query.text;
    this.detailsId = this.$route.query.detailsId;
    this.titleListData[this.titleListData.length - 1].label =
      this.text + "数量";
    this.getInventoryRecordDetailData();
  },
  methods: {
    // 获取详情信息
    getInventoryRecordDetailData() {
      this.loading = true;
      getInventoryRecordDetail(this.detailsId)
        .then(({ data }) => {
          if (data.code == 0) {
            this.detailsData = data.data;
            this.tableData = data.data.recordDetailList;
          } else {
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-content {
  padding: 0 20px 20px;
}
.flex-row {
  display: flex;
  align-items: center;
}
.box {
  /*强制文本在一行内显示*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
</style>
